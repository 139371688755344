import Header from "../components/header/Header";
import Projects from "./Projects";
function Home() {
  return (
    <>
      <Header />
      <Projects />
    </>
  );
}
export default Home;
