import "./style.css";
function Header() {
  return (
    <header className="header">
      <div className="header__wrapper">
        <h1 className="header__title">
          <strong>Alliance Audit</strong>
          <br />
          <em>аудиторско-консалтинговая компания</em>
        </h1>

        <a href="mailto:office@allianceaudit.org" className="btn">
          Связаться
        </a>
      </div>
    </header>
  );
}
export default Header;
