import project01 from "../img/projects/01.svg";
import project01Big from "../img/projects/01.svg";
import project02 from "../img/projects/02.svg";
import project02Big from "../img/projects/02.svg";
import project03 from "../img/projects/03.svg";
import project03Big from "../img/projects/03.svg";

const projects = [
  {
    title:
      "Оценка системы внутреннего контроля в целях противодействия ФТД/ЛПД",
    skills: "",
    img: project01,
    imgBig: project01Big,
    gitHubLink: "mailto:office@allianceaudit.org",
  },
  {
    title: "Аудит информационных систем и информационной безопасности",
    skills: "",
    img: project02,
    imgBig: project02Big,
    gitHubLink: "mailto:office@allianceaudit.org",
  },
  {
    title: "Аутсорсинг бухгалтерского учета и налоговое сопровождение",
    skills: "",
    img: project03,
    imgBig: project03Big,

    gitHubLink: "mailto:office@allianceaudit.org",
  },
];

export { projects };
