import './style.css';
import telegram from '../../img/icons/telegram-logo.png';
import whatsapp from '../../img/icons/whatsapp-logo.png';
import phone from '../../img/icons/phone-logo.png';
import email from '../../img/icons/email-logo.png';
function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <ul className="social">
            <li className="social__item">
              <a href="https://t.me/ShaQirov">
                <img src={telegram} alt="Link" />
              </a>
            </li>
            <li className="social__item">
              <a href="https://wa.me/996709033334">
                <img src={whatsapp} alt="Link" />
              </a>
            </li>
            <li className="social__item">
              <a href="tel:+996709033334">
                <img src={phone} alt="Link" />
              </a>
            </li>
            <li className="social__item">
              <a href="mailto:office@allianceaudit.org">
                <img src={email} alt="Link" />
              </a>
            </li>
          </ul>
          <div className="copyright">
            <p>© 2024 AllianceAudit.org</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
