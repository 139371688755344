import "./style.css";

function BtnGitHub({ link }) {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="btn-outline">
      Связаться
    </a>
  );
}
export default BtnGitHub;
